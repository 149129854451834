/* Add this to your global styles (e.g., index.css) or component CSS */

.slick-list {
    overflow: hidden !important; /* Hide any overflowing content */
    margin: 0 -10px; /* Prevent partial cards on the edges */
  }
  
  .slick-slide > div {
    margin: 0 10px; /* Add spacing between slides */
  }
  
  .slick-dots li button:before {
    color: teal !important; /* Change indicator color to teal */
  }
  
  .slick-dots li.slick-active button:before {
    opacity: 1 !important;
  }

  .slick-prev:before,
  .slick-next:before {
      color: #00695C; 
  }
  