.marquee {
    overflow: hidden;
    white-space: nowrap;
  }
  
  .marquee-content {

  }
  
  .marquee-content li {
    display: inline-block;
    padding: 0 20px; /* Adjust spacing between icons */ 
  }
  
  @keyframes marquee {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(-100%);
    }
  }
  